import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function AddCustomVariety({ open, handleSave, handleClose, heightOfKernel, setHeightOfKernel, PoniyaThreshold, setPoniyaThreshold, brokenThreshold, setBrokenThreshold, handleCheckboxChange, addThreshold,
    chalkyAreaForVariety, chalkyQualityForVariety, discolorAreaForVariety, discolorQualityForVariety, handleSliderforChalkyAreaForVariety, handleSliderForChalkyQualityForVariety, handleSliderforDiscolourAreaForVariety, handleSliderforDiscolourQualityForVariety }) {

    const handleBrokenThresholdChange = (e) => {
        const inputValue = e.target.value;
        if (/^\d*$/.test(inputValue) && parseInt(inputValue) < parseInt(PoniyaThreshold)) {
            setBrokenThreshold(inputValue);
        } else {
            setBrokenThreshold('');
            alert(`Broken kernel threshold should be less than ${PoniyaThreshold} %`);
        }
    };

    const handlePoniyaThresholdChange = (e) => {
        const inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) {
            setPoniyaThreshold(inputValue);
        }
    };
    const handleHeightChange = (e) => {
        const inputValue = parseFloat(e.target.value);
        if (/^\d*\.?\d{0,3}$/.test(inputValue) && parseFloat(inputValue) >= 0 && parseFloat(inputValue) < 15) {
            setHeightOfKernel(inputValue);
        } else {
            setHeightOfKernel('');
            alert(`Enter height of perfect kernel below 15mm`);
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                disableEnforceFocus
                sx={{ '& .MuiBackdrop-root': { pointerEvents: 'none' } }}
            >
                <Box sx={style}>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" component="h2">
                            <strong>Add variety In Rice </strong>
                        </Typography>

                        <Box>
                            <div>
                                <div>
                                    <div>
                                        <TextField
                                            required
                                            className='w-100'
                                            label="Perfect height of a kernel(mm)"
                                            type='number'
                                            inputProps={{ min: 0, max: 14.999 }}
                                            value={heightOfKernel ? heightOfKernel : ''}
                                            onChange={handleHeightChange}
                                            style={{ border: '1px solid aliceblue', marginTop: '20px' }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            required
                                            className='w-100'
                                            label="Threshold for Poniya Kernels"
                                            value={PoniyaThreshold ? PoniyaThreshold : ''}
                                            type='text'
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            onChange={handlePoniyaThresholdChange}
                                            style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            required
                                            className='w-100'
                                            label="Threshold for Broken Kernels"
                                            type='number'
                                            value={brokenThreshold ? brokenThreshold : ''}
                                            onChange={handleBrokenThresholdChange}
                                            style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            control={<Checkbox checked={addThreshold} onChange={handleCheckboxChange} />}
                                            label="Do you want to add threshold?"
                                        />
                                    </div>
                                </div>
                            </div>
                            {addThreshold &&
                                <>
                                    <div style={{ display: 'flex', gap: "20px" }}>
                                        <div>
                                            <div>
                                                <label><strong>Chalky threshold</strong></label>
                                            </div>
                                            <div style={{ alignItems: 'center', marginTop: '30px' }}>
                                                <span style={{ marginRight: '10px' }}>Area %</span>
                                                <Slider
                                                    value={chalkyAreaForVariety}
                                                    onChange={handleSliderforChalkyAreaForVariety}
                                                    aria-label="Slider 1"
                                                    valueLabelDisplay="on"
                                                    valueLabelFormat={(value) => <span>{value}%</span>}
                                                    min={0}
                                                    max={100}
                                                    style={{ width: '200px' }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ alignItems: 'center', marginTop: '45px' }}>
                                                <span style={{ marginRight: '10px' }}>Quality %</span>
                                                <Slider
                                                    value={chalkyQualityForVariety}
                                                    onChange={handleSliderForChalkyQualityForVariety}
                                                    aria-label="Slider 2"
                                                    valueLabelDisplay="on"
                                                    valueLabelFormat={(value) => <span>{value}%</span>}
                                                    min={0}
                                                    max={100}
                                                    style={{ width: '200px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', gap: "20px", marginTop: '20px' }}>
                                        <div>
                                            <div>
                                                <label><strong>Discolour threshold</strong></label>
                                            </div>
                                            <div style={{ alignItems: 'center', marginTop: '30px' }}>
                                                <span style={{ marginRight: '10px' }}>Area %</span>
                                                <Slider
                                                    value={discolorAreaForVariety}
                                                    onChange={handleSliderforDiscolourAreaForVariety}
                                                    aria-label="Slider 1"
                                                    valueLabelDisplay="on"
                                                    valueLabelFormat={(value) => <span>{value}%</span>}
                                                    min={0}
                                                    max={100}
                                                    style={{ width: '200px' }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ alignItems: 'center', marginTop: '45px' }}>
                                                <span style={{ marginRight: '10px' }}>Quality %</span>
                                                <Slider
                                                    value={discolorQualityForVariety}
                                                    onChange={handleSliderforDiscolourQualityForVariety}
                                                    aria-label="Slider 2"
                                                    valueLabelDisplay="on"
                                                    valueLabelFormat={(value) => <span>{value}%</span>}
                                                    min={0}
                                                    max={100}
                                                    style={{ width: '200px' }}
                                                />
                                            </div>
                                        </div>
                                    </div></>
                            }
                            <Box className='d-flex g-20 justify-end m-t-30'>
                                <Button variant='outlined' className="buttonStyle" onClick={handleSave} >Save</Button>
                                <Button variant='outlined' className="buttonStyle" onClick={handleClose} >Cancle</Button>
                            </Box>
                        </Box>

                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default AddCustomVariety