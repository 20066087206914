export const variety_list_static_threshold = [
    'hybrid', 'IR8', 'IR64', 'ir64_boiled', 'gujrati', 'gujarat',
    'gujrati_boiled', 'gujarat_boiled', 'gujarati_moti', 'gujarat_moti', 'basmati', 'delight_basmati',
    'bori_basmati', 'sonam', 'sriram', 'tukdi', 'sella', 'steam', 'FCI_RRC'
]

export const getThresholdValues = (variety) => {
    let threshold_value = {};
    switch (variety) {
        case 'hybrid':
            threshold_value = {
                discolor_quality: 0.33,
                discolor_area: 0.05,
                chalky_quality: 0.55,
                chalky_area: 0.2,
            }
            break;
        case 'IR8':
            threshold_value = {
                discolor_quality: 0.22,
                discolor_area: 0.05,
                chalky_quality: 0.44,
                chalky_area: 0.2,
            }
            break;
        case 'IR64':
            threshold_value = {
                discolor_quality: 0.55,
                discolor_area: 0.05,
                chalky_quality: 0.44,
                chalky_area: 0.2,
            }
            break;
        case 'ir64_boiled':
            threshold_value = {
                discolor_quality: 0.55,
                discolor_area: 0.05,
                chalky_quality: 0.44,
                chalky_area: 0.2,
            }
            break;
        case 'gujrati':
        case 'gujarat':
            threshold_value = {
                discolor_quality: 0.55,
                discolor_area: 0.05,
                chalky_quality: 0.66,
                chalky_area: 0.2,
            }
            break;
        case 'gujrati_boiled':
        case 'gujarat_boiled':
            threshold_value = {
                discolor_quality: 0.55,
                discolor_area: 0.05,
                chalky_quality: 0.44,
                chalky_area: 0.2,
            };
            break;
        case 'gujarati_moti':
        case 'gujarat_moti':
            threshold_value = {
                discolor_quality: 0.33,
                discolor_area: 0.05,
                chalky_quality: 0.44,
                chalky_area: 0.2,
            }
            break;
        case 'basmati':
        case 'bori_basmati':
            threshold_value = {
                discolor_quality: 0.55,
                discolor_area: 0.05,
                chalky_quality: 0.22,
                chalky_area: 0.2,
            }
            break;
        case 'delight_basmati':
            threshold_value = {
                discolor_quality: 0.77,
                discolor_area: 0.05,
                chalky_quality: 0.55,
                chalky_area: 0.2,
            }
            break;
        case 'sonam':
            threshold_value = {
                discolor_quality: 0.11,
                discolor_area: 0.05,
                chalky_quality: 0.22,
                chalky_area: 0.2,
            }
            break;
        case 'sriram':
            threshold_value = {
                discolor_quality: 0.11,
                discolor_area: 0.05,
                chalky_quality: 0.00,
                chalky_area: 0.2,
            }
            break;
        case 'tukdi':
            threshold_value = {
                discolor_quality: 0.66,
                discolor_area: 0.05,
                chalky_quality: 0.44,
                chalky_area: 0.2,
            }
            break;
        case 'sella':
            threshold_value = {
                discolor_quality: 0.55,
                discolor_area: 0.05,
                chalky_quality: 0.44,
                chalky_area: 0.2,
            }
            break;
        case 'steam':
            threshold_value = {
                discolor_quality: 0.55,
                discolor_area: 0.05,
                chalky_quality: 0.55,
                chalky_area: 0.2,
            }
            break;
        case 'FCI_RRC':
            threshold_value = {
                discolor_quality: 0.0,
                discolor_area: 0.05,
                chalky_quality: 0.55,
                chalky_area: 0.75,
            }
            break;
        default:
            threshold_value = null
            break;
    }
    return threshold_value
}