import { Card, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '../Table'
import moment from 'moment/moment'
import { getUserById, getUserScanPDF } from '../utils/api'
import { BsFileEarmarkPdfFill } from 'react-icons/bs'
import CircularLoadingPopUp from '../widgets/CircularLoadingPopUp'

function ScanHistroy() {
    const [scansList, setScanList] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingPdf, setLoadingPdf] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    useEffect(() => { onhandleSearch() }, [searchTerm]);

    useEffect(() => {
        window.addEventListener('resize', () => setWindowSize(window.innerWidth));
        return () => {
            window.removeEventListener('resize', () => setWindowSize(window.innerWidth));
        };
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const id = localStorage.getItem('userID')
                const response = await getUserById(id)
                if (response?.data?.data) {
                    const dataList = response?.data?.data && response?.data?.data.all_scans.map((item, index) => (
                        { ...item, id: index }
                    ))
                    response?.data?.data && setScanList(dataList)
                    response?.data?.data && setFilteredData(dataList)
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const onhandleSearch = () => {
        if (scansList) {
            const filteredData = scansList.filter((scan) => (
                scan.scan_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                moment(scan.scan_date).format('DD MMM, YYYY hh:mm A').toLowerCase().includes(searchTerm.toLowerCase())
            ))
            setFilteredData(filteredData);
        }
    };


    const downoadPdf = async (data) => {
        // console.log(data);
        try {
            setLoadingPdf(true)
            await getUserScanPDF(data.scan_id)
            setLoadingPdf(false)
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingPdf(false)
        }
    }

    const columns = [
        {
            field: 'serial_no',
            headerName: 'S No.',
            width: windowSize < 768 ? 80 : 200,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return `${params.row.id + 1}` },
        },
        {
            field: 'scan_id',
            headerName: 'Scan ID',
            width: windowSize < 768 ? 150 : 350,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return `${params.row.scan_id}` },
        },
        {
            field: 'Scan Date',
            headerName: 'Scan Date',
            width: windowSize < 768 ? 170 : 350,
            // type: 'date',
            // align: 'center',
            headerClassName: 'table-header',
            valueGetter: (params) => { return moment(params.row.scan_date).format('DD MMM, YYYY hh:mm A') },
        },
        {
            field: 'action',
            headerName: 'PDF',
            width: windowSize < 768 ? 110 : 200,
            sortable: false,
            filterable: false,
            headerClassName: 'table-header',
            renderCell: (rowData) => (
                <div>
                    <BsFileEarmarkPdfFill className='APP-color cursor' style={{ marginLeft: 10, fontSize: 20, textAlign: 'center' }} onClick={() => downoadPdf(rowData.row)} />
                </div>
            )
        }
    ];
    return (
        <div className='m-20'>
            {loadingPdf && <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>}
            {!loading ? <div>
                {scansList.length ? <div style={{ marginRight: '10px' }}>
                    <div className='d-flex justify-between flex-direction-custom '>
                        <p style={{ display: 'flex', justifyContent: 'start' }}><b>Scan Histroy List</b></p>
                        <input style={{ padding: 10, height: 40, borderRadius: 5, border: '1px solid skyblue' }} className='m-top-10' placeholder='Search' variant="outlined" onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    <Card className='m-t-10' sx={{ boxShadow: 0 }}><Table rows={filteredData} columns={columns} loading={loading} pageSizeOptions={[10, 25, 50, 100]} /></Card>
                </div> : ''}
            </div> : <CircularProgress />}
        </div>
    )
}

export default ScanHistroy