import React, { useState, useEffect } from 'react';
import { Input, InputAdornment, Menu, MenuItem, Fade, Avatar } from '@mui/material'

export default function AdvanceDropDown({
    title, width, required = false, reset_drop_down = false, badge_count = false,
    data_list, get_selected_data, unique_key, show_options_item, show_name_on_drop_down_box, filter_items,
    drop_down_variant, drop_down_background, input_placeholder, input_background, option_background, onChange
}) {
    const [menu_anchor, setMenuAnchor] = useState(null);
    const open = Boolean(menu_anchor);
    const [updated_list, setUpdatedList] = useState(null)
    const [selected_list, setSelectedList] = useState([])
    const [is_selected_all, setIsSelectedAll] = useState(false)

    useEffect(() => {
        setUpdatedList(data_list)
        if (reset_drop_down === true) {
            get_selected_data('')
            setSelectedList('')
        }
    }, [data_list])

    const handleClick = (event) => {
        setUpdatedList(data_list);
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
    };

    const onHandleInput = (input) => {
        const input_value = input.target.value.toLowerCase();

        const updated_data = updated_list && data_list && data_list.filter((element) => (
            filter_items.some((key) => {
                const item = element[key];
                return item && item.toLowerCase().includes(input_value);
            })
        ))

        setUpdatedList(updated_data);

        if (!input_value) {
            setUpdatedList(data_list);
        }
    };

    const onHandleClear = () => {
        setSelectedList('')
    }

    const onSelectItem = (option) => {
        setSelectedList(option)
        get_selected_data(option)
        handleClose();
    }

    return (
        <div>
            <Input
                onClick={handleClick}
                value={selected_list && selected_list[show_name_on_drop_down_box]}
                style={{ width: width, background: drop_down_background, padding: '10px 4px', border: '1px solid grey', borderRadius: 5, color: 'black' }}
                placeholder={required ? `${title}*` : title}
                variant={drop_down_variant}
                underline={false}
                readOnly
                required={required}
            />
            <Menu
                anchorEl={menu_anchor}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <div>
                    <Input
                        style={{ width: width, padding: 5, background: input_background }}
                        type='text'
                        placeholder={input_placeholder ? input_placeholder : 'Type here...'}
                        onChange={onHandleInput}
                    />
                </div>
                <div style={{ maxHeight: updated_list ? '250px' : '42px', overflowY: 'auto', background: option_background, color: option_background ? 'white' : '' }}>
                    {updated_list && updated_list.map((option, index) => (
                        <MenuItem key={index}
                            onClick={() => onSelectItem(option)}
                            onChange={onChange}
                            style={{
                                fontSize: '14px',
                                margin: 0,
                                textAlign: 'left',
                                width: width,
                                overflow: 'hidden',
                                whiteSpace: 'wrap',
                                padding: '15px 10px',
                                background: selected_list[unique_key] === option[unique_key] ? 'rgba(177, 209, 243, 0.664)' : ''
                            }}
                        >
                            {show_options_item(option)}</MenuItem>
                    ))}
                </div>
            </Menu>
        </div>
    )
}