import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({path, children }) => {
  const authToken = localStorage.getItem("token");
  const isLoggedIn = authToken !== null;

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // if(path === '/' && authToken) {
  //   return <Navigate to="dashboard/scan" replace />;
  //   // return <Navigate to="dashboard/users" replace />;
  // }

  return children;
};

export default ProtectedRoute;
