import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'
import Paper from '@mui/material/Paper';

function RiceBasedkernalLength({ riceBasedkernalLength }) {
    // console.log(Object.values(riceBasedkernalLength));

    return (
        <Box>
            <div style={{ marginTop: 20 }}>
                <TableContainer className='w-50 rs-width-100' component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontSize: 17}} align="left">Distribution Range</TableCell>
                                <TableCell style={{fontSize: 17}} align="left">Count</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Object.keys(riceBasedkernalLength).map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left" component="th" scope="row">
                                        <b>{row}</b>
                                    </TableCell>
                                    <TableCell align="left"><b>{riceBasedkernalLength[row]}</b></TableCell>
                                    
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </div>
        </Box>
    );
}

export default RiceBasedkernalLength