import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress } from '@mui/material';
import { BsFileEarmarkPdfFill } from 'react-icons/bs'
import { getUserScanPDF } from '../../utils/api';
import RiceBasedkernalLength from './RiceBasedkernalLength';
import CircularLoadingPopUp from '../../widgets/CircularLoadingPopUp';

function ResultScreen({ scanId, subScanId, cropList, cropId, scanResult, moisture, weight, visiblePDfButton, isChecked, saveTestVariety }) {
  const [cropName, setCropName] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(false)

  useEffect(() => {
    setCropName(cropId && cropList.find(crop => crop.id === cropId))
  }, [cropId])

  const downloadScanPDf = async () => {
    setLoadingPdf(true)
    try {
      await getUserScanPDF(scanId)
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPdf(false)
    }
  }

  return (
    <Box sx={{ marginTop: 5 }}>
      <Box className='d-flex justify-center fs-20'>
        <b><u>Result Screen</u></b>
      </Box>
      <Box className='d-flex justify-between flex-direction-custom'>
        <div>
          <div>Quality Assessment Report: <b>{cropName === null ? ' ' : cropName?.crop}</b></div>
          <div> {scanId && <p>Scan ID: <b>{scanId}</b></p>}</div>
          <div> {subScanId && <p>Sub Scan ID: <b>{subScanId}</b></p>}</div>
          <div>Quality Score:  <b>{`${scanResult?.kernel_details?.overall_score} %`}</b></div>
        </div>
        <div className='d-flex justify-content-end'>
          <div style={{ marginRight: '20px' }}>
            <Button
              style={{ marginTop: '40px' }}
              variant="contained"
              disabled={!visiblePDfButton}
              className={visiblePDfButton ? 'buttonStyle' : ''}
              startIcon={!visiblePDfButton ? <CircularProgress size={15} sx={{ color: 'white' }} /> : <BsFileEarmarkPdfFill />}
              onClick={downloadScanPDf}
            >
              Download Pdf
            </Button>
            {loadingPdf && <CircularLoadingPopUp />}
          </div>
          {isChecked &&
            <div>
              <Button
                style={{ marginTop: '40px' }}
                variant="contained"
                onClick={saveTestVariety}
              >
                Save Test
              </Button>
            </div>}
        </div>
      </Box>
      <div>
        <div>
          {scanResult && (
            <div>
              <TableComponent
                scanResult={scanResult}
                kernals={scanResult?.kernel_details}
                moisture={moisture}
                weight={weight}
              />

              {(scanResult?.kernel_details?.length_distributions?.distributions || scanResult?.kernel_details?.range_distributions?.distributions) && (
                <RiceBasedkernalLength
                  riceBasedkernalLength={scanResult?.kernel_details?.length_distributions?.distributions || scanResult?.kernel_details?.range_distributions?.distributions}
                />
              )}

            </div>
          )}
        </div>
      </div>
    </Box>
  )
}

export default ResultScreen



// Result Responce Table
export function TableComponent({ scanResult, moisture, weight }) {
  const rows = [];
  let headers = [
    {
      label: "",
      align: "left",
      headerColor: "#fff",
      renderer: (value, indexColumn, indexRow, row, rectRow, rectCell) => {
        return ``;
      },
    },
    {
      label: "Particulars",
      align: "left",
      headerColor: "#fff",
    },
  ];

  if (scanResult?.kernel_details?.overall_score_grading) {
    headers.push({
      label: "Grade",
      align: "left",
      headerColor: "#fff",
    });
  } else {
    headers.push({
      label: "Count",
      align: "left",
      headerColor: "#fff",
    })
    headers.push({
      label: "Count %",
      align: "left",
      headerColor: "#fff",
    });
  }

  if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
    headers.push({
      label: "Weight (gm)",
      align: "left",
      headerColor: "#fff",
    });
    headers.push({
      label: "Weight (%)",
      align: "left",
      headerColor: "#fff",
    });
  }

  if (scanResult?.kernel_details?.consider_area) {
    headers.push({
      label: "Area (mm2)",
      align: "left",
      headerColor: "#fff",
    });
  }

  if (scanResult?.kernel_details?.consider_length) {
    headers.push({
      label: "Length (mm)",
      align: "left",
      headerColor: "#fff",
    });
  }

  const firstRow = [];
  firstRow.push("");
  firstRow.push(
    scanResult?.kernel_details?.beautify?.translations?.total_kernel_count
  );

  if (scanResult?.kernel_details?.overall_score_grading) {
    firstRow.push(scanResult?.kernel_details?.overall_score_grading);
  } else {
    firstRow.push(scanResult?.kernel_details?.total_kernel_count);

    firstRow.push("100%");

  }
  if (parseFloat(Number(weight) ? Number(weight) : scanResult?.kernel_details?.total_kernel_weight) > 0) {
    firstRow.push(parseFloat(weight) > 0 ? parseFloat(weight).toFixed(2) : parseFloat(scanResult?.kernel_details?.total_kernel_weight).toFixed(2))
    firstRow.push("100%");
  }
  if (scanResult?.kernel_details?.consider_area) {
    firstRow.push("");
  }
  if (scanResult?.kernel_details?.consider_length) {
    firstRow.push("");
  }
  rows.push(firstRow);

  let totalCount = 0;

  scanResult?.kernel_details?.classes.forEach((key, index) => {
    let row = [];
    row.push({
      cellType: "box",
      color: scanResult?.processed_image?.encoding_colors[key],
    });
    row.push(
      scanResult?.kernel_details?.beautify?.translations?.kernel_counts[key] +
      " (" +
      scanResult?.processed_image?.encodings[key] +
      ")"
    );

    if (scanResult?.kernel_details?.overall_score_grading) {
      row.push(scanResult?.kernel_details?.grading[key]);
    } else {
      row.push(Number(scanResult?.kernel_details?.kernel_counts[key]));
      row.push(
        ` ${Number(
          (scanResult?.kernel_details?.kernel_counts[key] /
            scanResult?.kernel_details?.total_kernel_count) *
          100
        ).toFixed(2)}%`
      );
    }

    // if (parseFloat(scanResult?.kernel_details?.total_kernel_weight) > 0) {
    //       let weightKey = scanResult?.kernel_details?.kernel_weights
    //         ? scanResult?.kernel_details?.kernel_weights
    //         : scanResult?.kernel_details?.kernel_weight;
    //       row.push(Number(weightKey[key]).toFixed(3));
    //       row.push(
    //         ` ${Number(
    //           (weightKey[key] / scanResult?.kernel_details?.total_kernel_weight) *
    //           100
    //         ).toFixed(2)}%`
    //       );
    //     }

    if (parseFloat((scanResult?.kernel_details?.total_kernel_weight)) > 0) {
      let weightKey = scanResult?.kernel_details?.kernel_weights ? scanResult?.kernel_details?.kernel_weights : scanResult?.kernel_details?.kernel_weight;
      if (parseFloat(weight) === 0) {
        row.push(Number(weightKey[key]).toFixed(2))
        row.push(Number(weightKey[key] / (scanResult?.kernel_details?.total_kernel_weight) * 100).toFixed(2))
      } else {
        const weightedPercentageOfTotal = Number((Number(weightKey[key]).toFixed(2)) / (scanResult?.kernel_details?.total_kernel_weight) * 100);
        const finalWeightFromSampleWeight = Number(weightedPercentageOfTotal * parseFloat(weight ? weight : (scanResult?.kernel_details?.total_kernel_weight)) / 100)
        row.push(Number(finalWeightFromSampleWeight).toFixed(2))
        row.push(Number((Number(weightKey[key]).toFixed(2) / scanResult?.kernel_details?.total_kernel_weight * 100).toFixed(2)));
      }
    }

    if (scanResult?.kernel_details?.consider_area && scanResult?.kernel_details?.areas) {
      row.push(Number(scanResult?.kernel_details?.areas[key]).toFixed(2));
    } else {
      row.push('-');
    }

    if (scanResult?.kernel_details?.consider_length && scanResult?.kernel_details?.kernel_lengths) {
      row.push(
        Number(scanResult?.kernel_details?.kernel_lengths[key]).toFixed(2)
      );
    }
    const uniqueCombinations = new Map();

    const filteredKernel = Array.from(uniqueCombinations.values()).filter(
      (obj) => obj.originalClassName !== obj.changedClassName
    );
    const count = filteredKernel ? filteredKernel.length : 0;
    totalCount += count; // Increment the count

    rows.push(row);
  });

  const lastRow = [];
  lastRow.push("");
  lastRow.push("Moisture");
  // if (scanResult?.scan_metadata) {
  //   lastRow.push(
  //     scanResult?.scan_metadata?.moisture
  //       ? scanResult?.scan_metadata?.moisture + "%"
  //       : "NA"
  //   );
  // }
  if (moisture) {
    lastRow.push(`${moisture} %`)
  }

  rows.push(lastRow);

  const table = {
    title: "",
    subtitle: "",
    headers: headers,
    rows: rows,
  };

  return (
    <Box>
      <div style={{ marginTop: 20 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              style={{ backgroundColor: "aliceblue", textAlign: "left" }}
            >
              <TableRow>
                {table.headers.map((header, index) => (
                  <TableCell key={index} align={header.align}>
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.rows.map((row, index) => (
                <TableRow
                  key={index}
                  style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  {row.map((cell, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      align="left"
                      style={{
                        paddingRight: "7px",
                      }}
                    >
                      {cell && cell.cellType === "box" ? (
                        <div
                          style={{
                            backgroundColor: cell.color,
                            width: "20px",
                            height: "20px",
                          }}
                        ></div>
                      ) : (
                        <div>{cell}</div>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
}