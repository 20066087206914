import { Modal, Box, CircularProgress } from '@mui/material';
import React from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function CircularLoadingPopUp() {
    return (
        <div>
            <Modal
                open={true}
            >
                <Box sx={style}>
                    <Box className='d-flex justify-start'>
                        <CircularProgress size={30}/>
                        <p className='p-l-20 fs-20'><b><i>Loading...</i></b></p>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default CircularLoadingPopUp