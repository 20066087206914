import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

function Table({ rows, columns, loading, pageSizeOptions }) {
  const customLocaleText = {
    noRowsLabel: 'There are no records to display'
  };

  return (

    <div>
      <Box>
        <DataGrid
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',

            },
            '&.MuiDataGrid-root': {
              border: 'none',

            },
          }}
          getRowId={(row) => row.id}
          rows={rows}
          columns={columns}
          density='compact'
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSizeOptions[0],
              },
            },
          }}
          pageSizeOptions={pageSizeOptions}
          disableRowSelectionOnClick
          localeText={customLocaleText}
          loading={loading}
          classes={{
            columnHeader: 'disable-column-header-focus',
            cell: 'disable-cell-focus',
          }}

        />
      </Box>
    </div>
  );
}
export default Table