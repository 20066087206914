import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

const routes = [
    {
        path: "/dashboard/scan",
        name: "Scan",
        icon: <DocumentScannerOutlinedIcon sx={{height:20, width:20}}/>, 
    },
    {
        path: "/dashboard/scan-history",
        name: "Scan History",
        icon: <ManageHistoryIcon sx={{height:20, width:20}}/>,
    },
    {
        path: "/dashboard/how-to-scan-video",
        name: "How To Scan",
        icon: <VideocamOutlinedIcon sx={{height:20, width:20}}/>,
    },
];

export default routes