import React, { useEffect, useState } from 'react';
import circular_loading_gif from '../../src/assests/no_internet.png'

const InternetStatusChecker = ({ children }) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
        };

        const handleOffline = () => {
            setIsOnline(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return isOnline ? children :
        <div style={{width: '100%', height: '100%'}}>
            <div className='d-flex justify-center align-center'>
                <img src={circular_loading_gif} alt="Circular Loading" height='40%' width='40%' style={{ marginTop: 100 }} />
            </div>
        </div>;
};

export default InternetStatusChecker;


