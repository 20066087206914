export const processImage = (selectedFile, processedCvData) => {
    const bboxes = processedCvData?.bboxes;
    const encoding_colors = processedCvData?.encoding_colors;
    const short_name = processedCvData?.encodings;
    // const classess = processedCvData?.classes;
  
    return new Promise((resolve) => {
      const image = new File([selectedFile], 'processed.jpeg', {
        type: 'application/octet-stream',
      });
  
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
      const img = new Image();
      img.src = URL.createObjectURL(image);
  
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
  
        for (const category in bboxes) {
          if (bboxes.hasOwnProperty(category)) {
            const boxes = bboxes[category];
            const categoryColor = encoding_colors[category];
            if (categoryColor) {
              // ctx.strokeStyle = categoryColor; // Set stroke color dynamically
              // ctx.lineWidth = 8;
  
              for (const box of boxes) {
                const [x1, y1, x2, y2] = box;
                // ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);
              
                // Calculate center coordinates for the text
                const centerX = x1 + (x2 - x1) / 2;
                const centerY = y1 + (y2 - y1) / 2;
              
                // Add short_name at the center of each strokeRect
                const categoryName = short_name[category];
                ctx.fillStyle = categoryColor;
                ctx.font = '60px Arial'; 
                ctx.textAlign = 'center'; // Center align the text horizontally
                ctx.textBaseline = 'middle'; // Center align the text vertically
                ctx.fillText(categoryName, centerX, centerY);
              }
            }
          }
        }
  
        // Convert canvas to Blob with ".jpge" extension
        canvas.toBlob((blob) => {
          const processedImage = new File([blob], 'processed.jpeg', {
            type: 'application/octet-stream',
          });
          resolve(processedImage);
        }, 'image/jpeg', 0.5);
      };
    });
  };
  