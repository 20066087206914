import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from '../protected_route';
import Login from '../Login';
import Dashboard from '../routes/Dashboard';

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    <ProtectedRoute path={'/'}>
                        <Login />
                    </ProtectedRoute>
                } />
                <Route path="/dashboard/*" element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<>Not found</>} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;
