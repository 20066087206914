import React from 'react'
import { Route, Routes } from "react-router-dom";
import SideBar from '../sidebar/SideBar';
import Scan from '../components/scans/Scan';
import ScanHistroy from '../components/ScanHistroy';
import HowToScanVideo from '../components/HowToScanVideo';

function Dashboard() {
    return ( 
        <SideBar>
            <Routes>
                <Route path="/scan" element={<Scan />} />
                <Route path="/scan-history" element={<ScanHistroy />} />
                <Route path="/how-to-scan-video" element={<HowToScanVideo />} />
                <Route path="/*" element={<>Not Found</>} />
            </Routes>
        </SideBar>
    )
}

export default Dashboard