import axios from "axios";
import { auth } from "../firebase";
const url = window.config.endpoint;


const getRefreshedTokenId = async () => {
    return new Promise(async (resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            unsubscribe();
            try {
                const refreshedToken = await user.getIdToken(true);
                resolve(refreshedToken);
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    });
};

export const getAllCrops = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'GET',
            url: `${url}/crops/user`,

        })
        return responce
    } catch (error) {
        console.log(error);
        return error
    }
}

export const getAllMachines = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'GET',
            url: `${url}/machines/get/user`,
        })
        return responce
    } catch (error) {
        console.log(error);
        return error
    }
}

export const getScanID = async (crop_id, machine_code) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/scans/get_scan_id`,
            data: {
                crop_id,
                machine_code,
                new_scan: true
            }
        })
        return responce
    } catch (error) {
        console.log(error);
        return error
    }
}

export const getRequestConfigurations = async (crop_id, machine_code) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/crops/get/request_configurations`,
            data: {
                crop_id: crop_id,
                machine_code: machine_code
            }
        })
        return responce
    } catch (error) {
        console.log(error);
        // return error
    }
}

export const getUserScanMetaData = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'GET',
            url: `${url}/scans/get_user_metadata`,
        })
        return responce
    } catch (error) {
        console.log(error);
        return error
    }
}

export const postUserScanMetaData = async (scan_id, sub_scan_id, scanMetaData) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/scans/post_user_metadata`,
            data: {
                scan_id: scan_id,
                sub_scan_id: sub_scan_id,
                scan_metadata: scanMetaData
            }
        })
        return responce
    } catch (error) {
        console.log(error);
        return error
    }
}

export const postCvScanResult = async (scan_id, sub_scan_id, scan_result) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/scans/post_scan_result`,
            data: {
                scan_id: scan_id,
                sub_scan_id: sub_scan_id,
                scan_result: scan_result
            }
        })
        return responce
    } catch (error) {
        console.log(error);
        return error
    }
}
export const AddCustomVarietes = async (crop_name, variety_name, companyid, newVarietyName) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/crops/add_custom_variety`,
            data: {
                crop_name: crop_name,
                variety_name: variety_name,
                company_id: companyid,
                newVarietyName: newVarietyName
            }
        })
        return responce
    } catch (error) {
        console.log(error);
        return error.response
    }
}
export const postScanFiles = async (scan_id, sub_scan_id, fileArray) => {
    try {
        const token = await getRefreshedTokenId();

        const formData = new FormData();
        formData.append('scan_id', scan_id);
        formData.append('sub_scan_id', sub_scan_id);

        fileArray.map(itemFile => {
            const blob = new Blob([itemFile], { type: itemFile.type });
            formData.append("file", blob, itemFile.name);
        })


        const response = await axios({
            headers: {
                "Content-Type": "multipart/form-data", // Use 'multipart/form-data' for form data and file uploads
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/scans/post_scan_files`,
            data: formData
        });

        return response;
    } catch (error) {
        console.log(error);
        return error
    }
}


export const getUserScanPDF = async (scanId) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/scans/generate_scan_pdf_merged`,
            data: { scan_id: scanId, pdf_type: "LONG" },
            responseType: 'blob'
        })
        const blob = new Blob([responce.data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${scanId}.pdf`;
        link.click();

        URL.revokeObjectURL(blobUrl);
        return responce;
    } catch (error) {
        console.error(error);
        return error
    }
}

export const getUserById = async (firebase_user_id) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/dashboard/get_user_details`,
            data: {
                "firebase_user_id": firebase_user_id
            }
        })
        return responce
    } catch (error) {
        console.log(error);
        return error
    }
}