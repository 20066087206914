import React, { useState } from 'react'
import { auth, provider } from './firebase';
import { signInWithPopup, RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import { TextField, CircularProgress, Button, Card, CardContent, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import UpjaoLogo from './assests/Upjao_logo.png'
const url = window.config.endpoint;
function Login() {
  const navigate = useNavigate();
  const [loading, setLoding] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [OTP, setOTP] = useState();
  const [expandForm, setExpandForm] = useState(false);
  const [userName, setUserName] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isGoogleButtonDisable, setIsGoogleButtonDisable] = useState(false)
  const [isPhoneButtonDisable, setIsPhoneButtonDisable] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [isShowEmailAndPasswordOption, setIsShowEmailAndPasswordOption] = useState(false)
  const [isLoadingEmailAndPasswordOption, setIsLoadingEmailAndPasswordOption] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getuserRole = async (token) => {
    localStorage.setItem('token', token)
    if (token) {
      try {
        const [response] = await Promise.all([
          fetch(`${url}/users/get`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token}`
            },
          }),
        ])

        const userDetail = await response.json()

        console.log("responce", userDetail);

        if (userDetail.status) {
          localStorage.setItem("email", userDetail?.data?.userInfo?.email)
          localStorage.setItem("phoneNumber", userDetail?.data?.userInfo?.phone_number)
          localStorage.setItem('name', userDetail?.data?.userInfo?.first_name)
          localStorage.setItem('userID', userDetail?.data?.userInfo?.firebase_user_id)
          localStorage.setItem('company', userDetail?.data?.companyInfo?.name)
          localStorage.setItem('company_id', userDetail?.data?.companyInfo.id)
          navigate('/dashboard/scan')

        } else {
          setErrorMessage('Unauthorized User')
          setExpandForm(false)
          setGoogleLoading(false)
          setLoding(false)
          setIsGoogleButtonDisable(false)
          setIsPhoneButtonDisable(false)
        }
        setGoogleLoading(false)
      } catch (error) {
        console.error(error);
      }
    }
  }

  const signInwithGoogle = async () => {
    setGoogleLoading(true);
    setIsPhoneButtonDisable(true)
    await signInWithPopup(auth, provider).then(async (data) => {
      localStorage.setItem("name", data.user.displayName)
      localStorage.setItem("email", data.user.email)
      localStorage.setItem("photoURL", data.user.photoURL)
      // call api
      // getting user firebase token
      const token = await auth.currentUser.getIdToken(true)
      // console.log('token', token);
      token && getuserRole(token);
    }).catch((err) => {
      console.log(err);
      setGoogleLoading(false)
      setIsPhoneButtonDisable(false)
    })

  }

  // Sign in with mobile number
  const generateRecaptchaVerifier = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
      }
    });
  }

  const onSignInSubmit = (event) => {
    event.preventDefault();
    setLoding(!loading)
    generateRecaptchaVerifier();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, '+91' + phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoding(false)
        // alert("6-digit OTP Sent on your mobile number.")
        setExpandForm(true);
      }).catch((err) => {
        console.error("error", err.code);
        if (err.code === 'auth/too-many-requests') {
          setErrorMessage('Too Many Attemps, Please try again later.')
        } else {
          setErrorMessage('An Error occur, Please try again later.')
        }
        setLoding(false)
      });

  }

  const verifyOTPCode = (event) => {
    event.preventDefault();
    setLoding(!loading)
    if (OTP.length === 6) {
      window.confirmationResult.confirm(OTP).then(async (result) => {
        const user = result.user;
        // alert("otp verified");
        localStorage.setItem('phoneNumber', user.auth.currentUser.phoneNumber)
        const token = await auth.currentUser.getIdToken(true)
        console.log('tokenPhone', token);
        token && getuserRole(token);
      }).catch((err) => {
        console.error(err);
      });
    }
  }

  // handle phone input 
  const handlePhoneNumber = (event) => {
    if (event.target.value) {
      setErrorMessage('')
      setIsGoogleButtonDisable(true)
    } else {
      setIsGoogleButtonDisable(false)
    }

    if (event.target.value.length) {
      setPhoneNumber(event.target.value);
    }
  }

  // handle otp input
  const handleInputOTP = (event) => {
    // if (event.target.value.length === 6) {
    setOTP(event.target.value)
    // } 
  }

  const onHandleEmailAndPassword = (event) => {
    event.preventDefault();
    setIsLoadingEmailAndPasswordOption(true)
    signInWithEmailAndPassword(auth, userName, userPassword)
      .then(async (userCredential) => {
        // Signed in 
        // const user = userCredential.user;
        const token = await auth.currentUser.getIdToken(true)
        token && getuserRole(token);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        if (error.code === 'auth/wrong-password') {
          setErrorMessage('Invalid User Name and Password')
        } else {
          setErrorMessage('An Error occur, Please try again later.')
        }
      });
    setIsLoadingEmailAndPasswordOption(false)
  }

  return (
    <div>

      <div className='parent-container'>
        <div >
          <Card style={{ width: '400px', borderRadius: '10px', backgroundColor: '#ffffffab' }}>
            <CardContent>
              {isShowEmailAndPasswordOption ?
                <form onSubmit={onHandleEmailAndPassword}>
                  <div>
                    <KeyboardBackspaceIcon
                      className='APP-color cursor'
                      size={25}
                      onClick={() => setIsShowEmailAndPasswordOption(!isShowEmailAndPasswordOption)}
                    />
                    <div className='d-flex justify-center'>
                      <img
                        style={{ height: '87px', width: '151px' }}
                        alt="logo"
                        src={UpjaoLogo}
                      />
                    </div>

                    {errorMessage && <Alert style={{ marginBottom: '5px' }} severity="warning" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>}

                    <TextField
                      required
                      className='w-100 m-t-10'
                      label="User Name"
                      type='text'
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />

                    <TextField
                      required
                      className='w-100 m-t-10'
                      label="Password"
                      type='text'
                      value={userPassword}
                      onChange={(e) => setUserPassword(e.target.value)}
                    />

                    <div>
                      <Button
                        className='w-100 m-t-10 APP-background'
                        variant="contained"
                        type='submit'
                      > {isLoadingEmailAndPasswordOption ? <span><CircularProgress size={18} className='white' /> Loading </span> : 'Submit'}</Button>
                    </div>

                  </div>
                </form>
                : <form onSubmit={expandForm ? verifyOTPCode : onSignInSubmit}>
                  <div className='d-flex justify-center'>
                    <img
                      style={{ height: '87px', width: '151px' }}
                      alt="logo"
                      src={UpjaoLogo}
                    />
                  </div>
                  {errorMessage && <Alert style={{ marginBottom: '5px' }} severity="warning" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>}
                  <div className='m-t-20'>
                    <Button
                      className='w-100 APP-background'
                      variant="contained"
                      onClick={signInwithGoogle}
                      disabled={isGoogleButtonDisable}
                    >
                      {!googleLoading ? <div><img src="https://gitlab.com/assets/auth_buttons/google_64-9ab7462cd2115e11f80171018d8c39bd493fc375e83202fbb6d37a487ad01908.png" width={20} height={20} />
                        &nbsp; <span style={{ fontWeight: '500' }} id='loginWithGoogle'>SIGN IN WITH GOOGLE</span> </div> : <div id='googleLoadingSpinner'> &nbsp; <span style={{ fontWeight: '500' }}><CircularProgress size={18} className='white' />&nbsp; </span>Loading...</div>}
                    </Button>

                    <h4 className='text-line gray m-t-10'> OR </h4>
                  </div>

                  <div className='m-t-10'>
                    <TextField
                      required
                      className='w-100'
                      label="Phone Number"
                      type='tel'
                      onChange={(e) => handlePhoneNumber(e)}
                    />

                    {expandForm && <div>
                      <FormControl style={{ width: '100%', marginTop: '10px' }} variant="outlined">
                        <InputLabel >OTP</InputLabel>
                        <OutlinedInput

                          type={showPassword ? 'text' : 'password'}
                          onChange={(e) => handleInputOTP(e)}
                          required
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle otp visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="OTP"
                        />
                      </FormControl>
                    </div>}


                    {loading
                      ? <Button
                        className='w-100 m-t-10 APP-background'
                        color={"primary"}
                        id='loading'
                        variant="contained"
                      >
                        <span><CircularProgress size={18} className='white' /> &nbsp;  </span><span> Loading...</span>
                      </Button>
                      : <Button
                        className='w-100 m-t-10 APP-background'
                        variant="contained"
                        type='submit'
                        id='verify-button'
                        disabled={expandForm ? OTP?.length !== 6 : isPhoneButtonDisable}
                      > {expandForm ? "VERIFY OTP" : "VERIFY PHONE"}
                      </Button>}

                    <div>
                      <h4 className='text-line gray m-t-10'> OR </h4>
                      <Button
                        className={`w-100 m-t-10 APP-background`}
                        variant="contained"
                        disabled={isPhoneButtonDisable || isGoogleButtonDisable}
                        onClick={() => setIsShowEmailAndPasswordOption(!isShowEmailAndPasswordOption)}
                      >User Name & Password</Button>
                    </div>
                  </div>
                </form>}
            </CardContent>

          </Card>
        </div>
      </div>

      <div id='recaptcha-container'></div>
    </div>
  )
}

export default Login