import React from 'react';
import introVideo from '../assests/intro.mp4';

function HowToScanVideo() {
    return (
        <div>
            <div className='d-flex justify-center align-center rs-m-10'>
                <video style={{ marginTop: 100, border: '1px solid black', borderRadius: 5 }} width="500" height="360" controls>
                    <source src={introVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}

export default HowToScanVideo;
