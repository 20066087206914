import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid aliceblue',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

function WeightAndMoisture({ open, setOpen, weight, setWeight, moisture, setMoisture }) {

  const handleClose = () => {
    setOpen(false)
    setMoisture(0)
    setWeight(0)
  };


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="h2">
              <strong>Enter Details</strong>
            </Typography>

            <Box>
              <div>
                <div>
                  <div>
                    <TextField
                      required
                      className='w-100'
                      label="Enter Weight"
                      type='number'
                      value={weight ? weight : ''}
                      onChange={(e) => setWeight(e.target.value)}
                      style={{ border: '1px solid aliceblue', marginTop: '20px' }}
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      className='w-100'
                      label="Enter Moisture"
                      type='number'
                      value={moisture ? moisture : ''}
                      onChange={(e) => setMoisture(e.target.value)}
                      style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                    />
                  </div>
                </div>
              </div>

              <Box className='d-flex justify-around m-t-30'>
                <Button variant='outlined' className="buttonStyle" onClick={handleClose} >Cancle</Button>
                <Button variant='outlined' className="buttonStyle" onClick={() => setOpen(false)} >Proceed</Button>
              </Box>

            </Box>

          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default WeightAndMoisture