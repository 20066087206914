import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  p: 5,
};

function PopUpMessage({ open, setOpen, title, message, isLoading }) {
  const messageLines = message?.split('\n');
  const handleClose = () => setOpen(!open);

  return (
    <div>
      <Modal open={open}
        onClose={handleClose}
        disableEnforceFocus
        sx={{ '& .MuiBackdrop-root': { pointerEvents: 'none' } }}
      >
        <Box sx={style}>
          <Box className='d-flex justify-between'>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <b className={title === 'Warning' ? 'yellow' : 'APP-color'}>{title}</b>
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} style={{ marginLeft: '20px' }}>
              <CloseIcon className={title === 'Warning' ? 'yellow' : 'APP-color'} />
            </IconButton>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
            {isLoading ? (
              <Backdrop open={true}>
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <>
                {messageLines?.length > 0 &&
                  messageLines?.map((line, index) => (
                    <React.Fragment key={index}>
                      {line} <br />
                    </React.Fragment>
                  ))}
                <Button
                  style={{ position: 'absolute', bottom: 16, right: 41 }}
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                >
                  OK
                </Button>
              </>
            )}
          </Typography>
        </Box>
      </Modal>
    </div >
  );
}

export default PopUpMessage;
